import css from './Footer.module.scss'

import HomeDefense from '../../../../assets/homedefense.svg'
import GilrsDefense from '../../../../assets/girlsdefense.svg'
import Casabella from '../../../../assets/casabella.svg'
import Defendo from '../../../../assets/dkm_lion_white.svg'
import TactiClub from '../../../../assets/tacticlub.svg'

const Footer = () => (
  <footer className={css.Footer}>
    <span className={css.PayOff}>
      Poligono al chiuso per formazione con armi T4E Umarex e difesa
      professionale
    </span>
    <ul className={css.UlPartners}>
      <li>
        <a
          href='https://homedefense.it'
          target='_blank'
          rel='noopener noreferrer'
          className={css.LnkPartner}
        >
          <img src={HomeDefense} alt='home defense' />
        </a>
      </li>
      <li>
        <a
          href='https://armeriacasabella.it/'
          target='_blank'
          rel='noopener noreferrer'
          className={css.LnkPartner}
        >
          <img src={Casabella} alt='Casabella' />
        </a>
      </li>
      <li>
        <a
          href='https://defendokravmaga.it'
          target='_blank'
          rel='noopener noreferrer'
          className={css.LnkPartner}
        >
          <img src={Defendo} alt='Defendo Krav Maga' />
        </a>
      </li>
      <li>
        <a
          href='https://tacti.club'
          target='_blank'
          rel='noopener noreferrer'
          className={css.LnkPartner}
        >
          <img src={TactiClub} alt='Tacti.Club' />
        </a>
      </li>
      <li>
        <a
          href='https://girlsdefense.it'
          target='_blank'
          rel='noopener noreferrer'
          className={css.LnkPartner}
        >
          <img src={GilrsDefense} alt='girls defense' />
        </a>
      </li>
    </ul>
    <a
      href='https://www.google.com/maps/dir/?api=1&destination=45.6447757,9.3411654&travelmode=driving'
      target='_blank'
      rel='noopener noreferrer'
      className={css.LnkLocation}
    >
      <i className='fas fa-map-signs'></i> Via dell'Artigianato, 28 - Usmate
      Velate (MB)
    </a>
    <span className={css.HomeDefense}>
      <a
        href='https://homedefense.it'
        target='_blank'
        rel='noopener noreferrer'
        className={css.LnkLocation}
      >
        Home Defense Asd
      </a>
    </span>
    <ul className={css.UlHd}>
      <li>C.F. 03786290134</li>
      <li>NCAGE AR088</li>
      <li>UNGM 688556</li>
    </ul>
    <span className={css.Version}>
      &copy;{new Date().getFullYear()} Kill House - v.{' '}
      {document.head.querySelector('meta[name="build-version"]')['content']}
    </span>
  </footer>
)

export default Footer
