import { Fragment, useEffect } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
// import stealth from '@stltio/stealth'

import css from './Layout.module.scss'
import LoadCollections from './LoadCollections/LoadCollections'
import NavBar from './NavBar/NavBar'
import Social from './Social/Social'
import Footer from './Footer/Footer'
import Logo from '../../../assets/logo_tan.svg'
import NotificationQueue from '../../UI/NotificationQueue/NotificationQueue'

const Layout = () => {
  const location = useLocation()

  // useEffect(() => {
  //   stealth({
  //     apiKey: '84fb371df668fd8e2968d492a4a4fbde231ddd20f0b63c0474f36049182b6260'
  //   })
  // }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <Fragment>
      {location.pathname !== '/' && (
        <Link to='/' className={css.Logo}>
          <img src={Logo} alt='Logo' />
        </Link>
      )}
      <NavBar />
      <Social />
      <Outlet />
      <LoadCollections />
      <Footer />
      <NotificationQueue />
    </Fragment>
  )
}

export default Layout
