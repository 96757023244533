import moment from 'moment'

export const timeHelpers = {
  now,
  timeToHours,
  timeToDate,
  timeToDateFull,
  dayOfWeek,
  month,
  startOfMonth,
  monthYear,
  daysInMonth,
  addMonths,
  addDays,
  isSameDay,
  startOfDay
}

function timeToHours(input) {
  const sd = moment.unix(input).utc().startOf('day').utc().unix()
  const diff = input - sd
  let m = diff % 3600
  let h = (diff - m) / 3600
  if (m.toString().length === 1) {
    m = `0${m}`
  }
  if (h.toString().length === 1) {
    h = `0${h}`
  }
  return `${h}:${m}`
}

function now() {
  return moment.utc().unix()
}

function timeToDate(input) {
  return moment.unix(input).utc().format('DD MMMM YYYY')
}

function timeToDateFull(input) {
  return moment.unix(input).utc().format('YYYY-MM-DD HH:mm')
}

function dayOfWeek(input, format = 'dddd') {
  return moment.unix(input).utc().format(format)
}

function month(input) {
  return moment.unix(input).utc().format('MMMM')
}

function monthYear(input) {
  return moment.unix(input).utc().format('MMMM YYYY')
}

function startOfMonth(input) {
  if (input) {
    return moment.unix(input).utc().startOf('month').unix()
  }
  return moment.utc().startOf('month').unix()
}

function daysInMonth(input) {
  return moment.unix(input).utc().daysInMonth()
}

function addMonths(input, value) {
  return moment.unix(input).utc().add(value, 'months').unix()
}

function isSameDay(a, time, b) {
  return moment
    .unix(a)
    .utc()
    .isSame(moment.unix(time).utc().add(b, 'days'), 'day')
}

function addDays(input, value) {
  return moment.unix(input).utc().add(value, 'days').unix()
}

function startOfDay(input) {
  return moment.unix(input).utc().startOf('day').unix()
}
