import { reservationsConstants } from '../constants'
import { uiConstants } from '../../constants'

export const loadReservations = () => {
  return {
    type: reservationsConstants.RESERVATIONS_INIT
  }
}

export const updateReservations = (payload) => {
  return {
    type: reservationsConstants.RESERVATIONS_UPDATE,
    payload
  }
}

export const removeReservations = (payload) => {
  return {
    type: reservationsConstants.RESERVATIONS_REMOVE,
    payload
  }
}

export const bookReservations = (payload) => {
  return {
    type: reservationsConstants.RESERVATIONS_BOOK,
    payload
  }
}

export const bookReservationsSuccess = () => {
  return {
    type: reservationsConstants.RESERVATIONS_BOOK_SUCCESS,
    payload: uiConstants.messages.reservationsSuccess
  }
}

export const bookReservationsFailure = () => {
  return {
    type: reservationsConstants.RESERVATIONS_BOOK_FAILURE,
    payload: uiConstants.messages.reservationsFailure
  }
}
