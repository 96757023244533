import { combineReducers } from 'redux'

import gallery from './gallery.reducer'
import events from './events.reducer'
import reservations from './reservations.reducer'
import notification from './notification.reducer'

const rootReducer = combineReducers({
  gallery,
  events,
  reservations,
  notification
})

export default rootReducer
