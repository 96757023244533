export const menuConstants = {
  links: [
    { id: 'home', label: 'Home', to: '/' },
    {
      id: 'structure',
      label: 'Struttura',
      to: '/struttura'
    },
    {
      id: 'events',
      label: 'Eventi',
      to: '/eventi'
    },
    {
      id: 'calendar',
      label: 'Calendario',
      to: '/calendario'
    },
    {
      id: 'gallery',
      label: 'Gallery',
      to: '/gallery'
    },
    {
      id: 'videos',
      label: 'Video',
      to: '/video'
    }
  ]
}
