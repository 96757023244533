import { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'

import {
  loadEvents,
  loadGallery,
  loadReservations
} from '../../../../redux/actions'
import PageLoader from '../../../UI/PageLoader/PageLoader'

const LoadCollections = ({ reservations }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadGallery())
    dispatch(loadEvents())
    dispatch(loadReservations())
  }, [dispatch])

  return reservations.loading ? <PageLoader /> : null
}

export default connect((state) => {
  return state
}, {})(LoadCollections)
