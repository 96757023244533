import { put, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import { eventsRef } from '../../database'
import moment from 'moment'
import { onSnapshot, query, where } from 'firebase/firestore'

import { updateEvents, removeEvents } from '../actions'

export let eventsSnap

export function* loadEventsSaga() {
  try {
    const startDate = moment.utc().add('-7', 'days').unix()
    const listener = eventChannel((emit) => {
      const q = query(eventsRef, where('date', '>=', startDate))
      eventsSnap = onSnapshot(q, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          emit(change)
        })
      })
      return () => eventsSnap()
    })
    while (true) {
      const change = yield take(listener)
      const data = change.doc.data()
      const id = change.doc.id
      if (id === '_._') continue
      if (change.type === 'added') {
        yield put(updateEvents({ id, ...data }))
      }
      if (change.type === 'modified') {
        yield put(updateEvents({ id, ...data }))
      }
      if (change.type === 'removed') {
        yield put(removeEvents({ id, ...data }))
      }
    }
  } catch (error) {
    console.log(error)
  }
}
